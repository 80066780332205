import React from "react";
import BenefitItem from "./BenefitItem";
import { 
    StyledBenefitsWrapper,
    StyledSectionTitle,
    StyledBenefitItemsContainer,
} from "./styled";

const InlineBenefitsSection = ({ title, configuration }) => {
    return (
        <StyledBenefitsWrapper>
            <StyledSectionTitle>{title}</StyledSectionTitle>
            <StyledBenefitItemsContainer>
                {configuration.map((props) => <BenefitItem {...props} />)}
            </StyledBenefitItemsContainer>
        </StyledBenefitsWrapper>
    )

}

export default InlineBenefitsSection;