import React from "react";
import { 
    StyledBenefitItem,
    StyledBenefitsItemTitle,
    StyledBenefitsItemDescription
} from "../styled";

const BenefitItem = ({ title, description }) => {
    return (
    <StyledBenefitItem>
        <StyledBenefitsItemTitle>{title}</StyledBenefitsItemTitle>
        <StyledBenefitsItemDescription>{description}</StyledBenefitsItemDescription>
    </StyledBenefitItem>
    )

}

export default BenefitItem;