import React from "react"
import InlineBenefitsSection from "@common/CommonSections/InlineBenefitsSection"
import { StyledMarginWrapper } from "../../common-styles"
import { INLINE_BENEFITS_SECTION_TITLE, BENEFIT_ITEMS } from "../../constants"

const Benefits = () => {
  return (
    <StyledMarginWrapper>
      <InlineBenefitsSection
        title={INLINE_BENEFITS_SECTION_TITLE}
        configuration={BENEFIT_ITEMS}
      />
    </StyledMarginWrapper>
  )
}

export default Benefits
